import React from "react"
import QueueAnim from "rc-queue-anim"
import "./useCase.scss"

import collater from "../../images/useCases/collater.svg"
import crossChain from "../../images/useCases/crossChain.svg"
import feeAgg from "../../images/useCases/feeAgg.svg"
import govemance from "../../images/useCases/govemance.svg"
import liquidity from "../../images/useCases/liquidity.svg"
import metaver from "../../images/useCases/metaver.svg"
import nftBridge from "../../images/useCases/nftBridge.svg"
import oneClick from "../../images/useCases/oneClick.svg"
import purchasing from "../../images/useCases/purchasing.svg"
import synchron from "../../images/useCases/synchron.svg"
import tokenBridge from "../../images/useCases/tokenBridge.svg"
import UseIsMobile from "../../hooks/UseIsMobile"

/* eslint-disable */

const list0 = [
  {
    imgUrl: crossChain,
    title: "Cross-chain yield farming",
    desc:
      "Enabling users to enter and exit yield farms on a multitude of blockchains, without having to deal with token bridges, swaps, slippage, or transaction costs on the receiving blockchains",
  },

  {
    imgUrl: synchron,
    title: "Cross-chain state synchronization",
    desc:
      "Syncing the states of multiple chains to provide a unified overarching state for the protocol",
  },
]
const list1 = [
  {
    imgUrl: feeAgg,
    title: "Cross-chain fee aggregation",
    desc: "Payment collection or aggregation on any supported chain",
  },
  {
    imgUrl: tokenBridge,
    title: "Token bridging",
    desc: "Projects can build their own token bridges across 20+ chains",
  },
]
const list2 = [
  {
    imgUrl: nftBridge,
    title: "NFT bridging",
    desc:
      "Users can send their NFTs across different chains while maintaining the attributes and utilities of those NFTs",
  },

  {
    imgUrl: collater,
    title: "Cross-chain NFT collateralization",
    desc:
      "Enabling users to securely lock their NFTs as collateral on the native chain in order to borrow crypto assets on another",
  },
]
const list3 = [
  {
    imgUrl: liquidity,
    title: "Cross-chain liquidity provision and management",
    desc:
      "Collateral can be provided on one chain in order to borrow assets on a different chain. Yielding vaults can be managed from any chain of a user’s choice",
  },
  {
    imgUrl: metaver,
    title: "Metaverse games that use items from multiple chains",
    desc:
      "Enabling a seamless gaming experience built with synchronized cross-chain states and using virtual items from various chains",
  },
]
const list4 = [
  {
    imgUrl: oneClick,
    title: "One-click one-tx cross-chain swaps",
    desc:
      "Converting a multi-step, multi-interaction token exchange flow into an easy single-click UX",
  },

  {
    imgUrl: purchasing,
    title: "Cross-chain NFT bidding and purchasing",
    desc:
      "NFT marketplaces can allow their users to initiate a cross-chain purchase or bid for an NFT using tokens on any chain",
  },
]
const list5 = [
  {
    imgUrl: govemance,
    title: "Cross-chain governance",
    desc: "Enabling governance token holders to vote from any chain",
  },
]
const list = [list0, list1, list2, list3, list4, list5]
const listMobile = list0
  .concat(list1)
  .concat(list2)
  .concat(list3)
  .concat(list4)
  .concat(list5)

export default function UseCaseList() {
  const [isMobile] = UseIsMobile()
  const MyQueueAnim = isMobile ? "div" : QueueAnim

  if (isMobile) {
    return (
      <div className="useCaseMobile">
        <div className="useCaseMobileTitle">
          Celer IM {isMobile && <div />}Use Cases
        </div>
        {listMobile.map((item, index) => {
          return (
            <div className="textItem " key={index}>
              <img src={item.imgUrl} className="listImg" alt="" />
              <div className="textTitle">{item.title}</div>
              <div className="textDesc">{item.desc}</div>
            </div>
          )
        })}
      </div>
    )
  }
  return (
    <div className="useCase">
      <div className="useCaseBody">
        <MyQueueAnim duration={2000} type="bottom">
          <div className="useCaseTitle" key="useCaseTitle">
            Celer IM Use Cases
          </div>
        </MyQueueAnim>
        <div className="useCaseContent">
          {list.map((item, index) => {
            return (
              <div className="useCaseContentLevel" key={index}>
                {item.map((iitem, iindex) => {
                  return (
                    <div className="useCaseContentItem" key={iindex}>
                      <MyQueueAnim duration={1000} type="bottom">
                        <div
                          className="textImg"
                          key={"textImg" + index.toString() + iindex.toString()}
                        >
                          <img src={iitem.imgUrl} className="listImg" alt="" />
                        </div>
                      </MyQueueAnim>
                      <MyQueueAnim duration={1000} delay={200} type="bottom">
                        <div
                          className="textTitle"
                          key={
                            "textTitle" + index.toString() + iindex.toString()
                          }
                        >
                          {iitem.title}
                        </div>

                        <div
                          className="textDesc"
                          key={
                            "textDesc" + index.toString() + iindex.toString()
                          }
                        >
                          {iitem.desc}
                        </div>
                      </MyQueueAnim>
                    </div>
                  )
                })}
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}
