import React, { useEffect } from "react"
import Layout from "../components/layout"
import UseCaseList from "../components/useCaseList/UseCaseList"

export default function useCases() {
  useEffect(() => {
    window.onbeforeunload = function() {
      document.documentElement.scrollTop = 0
      document.body.scrollTop = 0
      document.body.style.background = "#fff"
      document.body.style.opacity = 0
    }
  }, [])
  return (
    <Layout>
      <UseCaseList />
    </Layout>
  )
}
